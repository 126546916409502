<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { ref } from 'vue';
import helper from '~/resources/js/libraries/helper';

const layoutStore = useLayoutStore();
const currencies = ref([]);

const config = useRuntimeConfig();
currencies.value = await layoutStore.currencies;

const currency = helper.cookies.currency();

watch(currency, () => window.location.href = window.location.href);
</script>
<template>
  <w-select-menu v-model="currency" :options="currencies" optionAttribute="code" class="w-full lg:w-20" >
    <template #option="{ option: currency }">
        <span class="cursor-pointer text-left w-full block px-4 py-2">{{ currency.code }}</span>
    </template>
  </w-select-menu>
</template>